












import { computed, defineComponent, SetupContext } from '@vue/composition-api'

import { FALLBACK_LOADER } from './Image.config'
import { ImageProps } from './Image.contracts'
import { imageProps, useImage } from './Image.hooks'

/**
 * Component capable to render Image element.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const Image = defineComponent({
  name: 'CustomImage',
  props: imageProps,
  emits: ['error', 'load'],

  setup (props: ImageProps, ctx: SetupContext) {
    /**
     * Source for the loading image (until the target image loads).
     */
    const loadingImage = computed<string>(() => FALLBACK_LOADER)

    return {
      isClient: typeof window !== 'undefined',
      loadingImage,
      ...useImage(props, ctx, ctx.emit)
    }
  }
})
export default Image
